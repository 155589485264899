<template>
  <div>
    <b-embed v-if="$route.name === 'stuff' && $route.params.type === 'movies' || $route.params.type === 'tv'"
      type="iframe"
      aspect="16by9"
      :src="'https://www.youtube.com/embed/' + bImg + '?autoplay=1&mute=1'"
      mute
      autoplay
      allowfullscreen
    ></b-embed>
    <b-img v-else :src="require('@/assets/img/splash/' + bImg)" />
  </div>
</template>

<script>
export default {
  name: 'splash',
  props: ['bImg'],
}
</script>
