import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/movies",
    name: "movies",
    component: Home
  },
  {
    path: "/:type/:id",
    name: "stuff",
    component: Home
  },
  {
    path: "/tv",
    name: "tv",
    component: Home
  },
  {
    path: "/dinosaurs",
    name: "dinosaurs",
    component: Home
  },
];

const router = new VueRouter({
  routes,

  // below will force scroll to the toop of the page when using the router
  
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});

export default router;
