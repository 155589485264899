<template>
  <div>
    <Splash :bImg="whichImg()" />
    <template v-if="$route.name === 'Home'">
      <GridView :item="home[0].home" />
    </template>
    <template v-if="$route.name === 'movies'">
      <Card :items="mov[0].movies" :where="where" />
    </template>
    <template v-if="$route.name === 'tv'">
      <Card :items="tvData[0].tv" :where="where" />
    </template>
    <template v-if="$route.name === 'dinosaurs'">
      <Card :items="dinos[0].dino" :where="where" />
    </template>
    <template v-if="$route.name === 'stuff'">
      <GridView :item="pickedItem[0]" />
    </template>
  </div>
</template>

<script>
import home from '@/assets/js/home'
import mov from '@/assets/js/movie'
import tvData from '@/assets/js/tv'
import dinos from '@/assets/js/dino'
import Splash from '@/components/splash.vue'
import Card from '@/components/card.vue'
import GridView from '@/components/gridView.vue'

export default {
  name: 'Home',
  components: {
    Splash,
    Card,
    GridView,
  },
  data() {
    return {
      mov: mov,
      tvData: tvData,
      dinos: dinos,
      home: home,
    }
  },
  methods: {
    whichImg(back) {
      if (this.$route.name === 'Home') {
        back = this.home[0].image
      } else if (this.$route.name === 'movies') {
        back = this.mov[0].image
      } else if (this.$route.name === 'tv') {
        back = this.tvData[0].image
      } else if (this.$route.name === 'dinosaurs') {
        back = this.dinos[0].image
      } else if (this.$route.name === 'stuff') {
        if (this.pickedItem[0].youTube === '') {
          back = this.pickedItem[0].image
        } else {
          back = this.pickedItem[0].youTube
        }
      }
      return back
    },
  },
  computed: {
    where() {
      return this.$route.name
    },
    itemData(dataSet) {
      var type = this.$route.params.type
      if (type === 'movies') {
        dataSet = this.mov[0].movies
      } else if (type === 'tv') {
        dataSet = this.tvData[0].tv
      } else if (type === 'dinosaurs') {
        dataSet = this.dinos[0].dino
      }
      return dataSet
    },
    pickedItem() {
      var data = this.itemData
      return data.filter((picked) => picked.title === this.$route.params.id)
    },
  },
}
</script>

<style>
.grid {
  padding: 75px 0;
}

.col {
  position: relative;
}
.textGroup {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  display: table;
}

.textGroup > p {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

p > .btn {
  margin-top: 20px;
}

@media only screen and (max-width: 425px) {
  .textGroup {
  position: relative;
  display: block;
  padding: 35px 0 0;
}

.textGroup > p {
  display: block;
}
.grid{
  padding: 25px 0;
}
}
</style>
