const data =[
    {
        title: 'Dinosaurs',
        image: 'dinosaur-apocalypse.jpg',
        dino: [
            {
                title:'Carnotaurus',
                image: 'Carnotaurus_splash.jpg',
                thumbnail:'Carno_Thumb.jpg',
                youTube:'',
                textOne:'Carnotaurus Sastrei is an Abelisaurid from 69-71 million years ago in what is now Argentinia. The species is only known from one well-preserved specimen that was uncovered in a farmers field, The sastrei part is a tribute to the farmer as Carnotaurus Sastrei roughly translates to Sastreis meat eating bull. It was the largest preditor in its domain and would have lived on a large flood plain chasing down prey and dispatching them with their stout and stocky heads ',
                imageOne:'Carnotaurus_Fossil.jpg',
                textTwo:'Carnotaurus has not faired well in Media adaptations as its most notable appearance is in Disneys dinosaur a movie that upon being mentioned makes any Dinosaur enjoyer shudder. Carnotaurus stars as the main antagonist stalking the herd over the course of the film. The animal is just awful, its far too large being 2 to 3 times the size of carnotaurs, the arms are actually functional and have elbows both of which were not features of the actual animals arms which were compeletely vestigal the legs are also far too fat and short. This is a generic lumbering dinosaur monster not Carnotaurus sasteri. Luckily The documentery Prehistoric Planet has done this dinosaur justice',
                imageTwo:'Disney_Carno.webp',
            },
            {
                title:'Dilophosaurus',
                image: 'Dilophosaurus_Splash.jpg',
                thumbnail:'Seagull.jpeg',
                youTube:'',
                textOne:'Dilophosaurus walkeri is a non avarosterone neotheropods, mouthfull I know.It lived in the early Jurrasic and is one of the first examples of a large apex theropod. However you wouldnt be able to tell that based on its most famous depiction in the Jurrasic park films, being presented as being the size of a child with an enourmous frill and the ability to spit venom all of which are untrue. Dilophosaurus grew up to 10 meters long and 2.2 meters tall, its skull was nearly twice the size of a polar bears skull. Unfortunatly it did not spit venom as their is no sign of a venom sack in the skull and the frill also makes little to no sense as animals hardly ever invest in two seperate display structures and the crest does that job just fine.',
                imageOne:'DIlo_image_1.jpeg',
                textTwo:'For a more in depth analysis I would highly recommend the youtube channel Your Dinosaurs Are Wrong as the channel goes into much greater detail of what we know and dont know about this animal.',
                imageTwo:'DIlo_size.png',
            },
            {
                title:'Majungasaurus',
                image: 'Majungasaurus_Splash.webp',
                thumbnail:'Majungasaurus_Thumb.webp',
                youTube:'',
                textOne:'Majungasaurs is an abelisaurid that lived during the late cretacious. Its most notable feature is that it is one of the few dinosaurs that has definte evidence of cannibalism. Specimins have been found with bite marks and lodged teeth from other Majungasaurs. Its primary prey would have been the small sauropod Rapetosaurus and other herbivours living on the island, as well as other Majungasaurs.',
                imageOne:'Majung_Cannibal.jpg',
                textTwo:'Majungasaurus is named after the Mahajunga province in Madagascar from where it was unearthed, it appears to be more closely related to the Indian Abelisaurs than the ones of continental Afica and South America',
                imageTwo:'Mahajunga.png',
            },
        ]
    }
]

export default data