<template>
  <b-container class="bv-example-row">
    <b-row class="grid">
      <b-col lg='6' md='6' cols='12'>
        <b-img :src="require('@/assets/img/gridImage/' + item.imageOne)" />
      </b-col>
      <b-col lg='6' md='6' cols='12'>
        <div class="textGroup">
          <p v-html="item.textOne">
            <br />
            <b-button
              v-if="$route.name === 'Home'"
              to="/movies"
              variant="primary"
            >
              Find out more
            </b-button>
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row class="grid">
      <b-col lg='6' md='6' cols='12' order='1' order-md='0' corder-lg='0'>
        <div class="textGroup">
          <p v-html="item.textTwo">
            <br />
            <b-button v-if="$route.name === 'Home'" to="/tv" variant="primary">
              Find out more
            </b-button>
          </p>
        </div>
      </b-col>
      <b-col lg='6' md='6' cols='12' order='0' order-md='1' order-lg='1'>
        <b-img :src="require('@/assets/img/gridImage/' + item.imageTwo)" />
      </b-col>
    </b-row>
    <b-row v-if="item.textThree !== undefined" class="grid">
      <b-col lg='6' md='6' cols='12'>
        <b-img :src="require('@/assets/img/gridImage/' + item.imageThree)" />
      </b-col>
      <b-col lg='6' md='6' cols='12'>
        <div class="textGroup">
          <p v-html="item.textThree">
            <br />
            <b-button
              v-if="$route.name === 'Home'"
              to="/dinosaurs"
              variant="primary"
            >
              Find out more
            </b-button>
          </p>
        </div>
        <div></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'stuff',
  props: ['item'],
}
</script>

