<template>
  <div class="grid">
    <b-container>
      <b-row>
        <b-col lg='4' md='6' cols='12' v-for="(stuff, index) in items" :key="index">
          <b-card
            :title="stuff.title"
            :img-src="require('@/assets/img/thumb/' + stuff.thumbnail)"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
            <b-card-text> </b-card-text>

            <b-button :to="'/' + where +  '/' + stuff.title" variant="primary"
              >More info</b-button
            >
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
    name:'card',
    props:['items', 'where']
}
</script>

<style scoped>
::v-deep .card-img-top {
  height: 445px;
  object-fit: cover;
}
</style>
