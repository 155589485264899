const data =[
    {
        title: 'Home',
        image: 'Gull.webp',
        home: 
            {
                textOne:'Here you can find some of the movies that I enjoy. Next to this you can see a generic movie poster!',
                imageOne:'Movie_poster.jpg',
                textTwo:'This button bellow will take you to some pretty swell TV shows!',
                imageTwo:'TV_1.webp',
                textThree:'Dinosaurs are just cool really. The two on the left are new spinosaurids that were found in England 2021!',
                imageThree:'DIno_1.webp'
            },
        
    }
]

export default data