const data =[
    {
        title: 'Movies',
        image: 'MOvies.jpg',
        movies: [
            {
                title:'Mosul',
                image: 'Mosul_title_shot.jpg',
                thumbnail:'Mosul_Thumb.jpg',
                youTube:'Z9bw8MGkB-A',
                textOne:'Mosul is a war action film based on the Battle of Mosul where Iraqi Government Forces and Nato member troops defeated ISIS/Daesh, who had controlled the city since June 2014.',
                imageOne:'OLd_Mosul.png',
                textTwo:'The movie focuses on the prospective of a former policeman turned Nevineh SWAT member as they aim to complete their objective which is unknown for most of the duration of the film. The movie does not hold back from the horrors of Daesh commited.',
                imageTwo:'Neveneh_Swat_team.jpg',
            },
            {
                title:'Judge Dredd',
                image: 'Dredd_Splash.jpg',
                thumbnail:'Dredd_Thumb.jpg',
                youTube:'G-eI5oLlIeY',
                textOne:'Dredd is an action movie and it does its job very well. If youre looking for a deep engrossing story with overarching plot points then this isnt where you should be looking, in Dredd action and spectacle come first.',
                imageOne:'Dredd_Action.jpeg',
                textTwo:'One of Dredds best aspects is the cinematography which is best demonstrated in the Slo mo scenes, youll have to watch it to witness the eye candy',
                imageTwo:'Dredd_Slo_mo_scene.jpg',
            },
            {
                title:'Whiplash',
                image: 'Whiplash_Splash.jpg',
                thumbnail:'Whiplash_thumb.jpg',
                youTube:'7d_jQycdQGo',
                textOne:'Whiplash is a 2014 film about mentorship and potential. It follows up and coming drumming student Newman and his mentor Fletcher. Fletcher is a supreme critic and master, he can see the most minute error where others see none and demands nothing short of perfection as "there are no two words in the English language more harmful than good job". ',
                imageOne:'Whiplash _Fletcher.jpeg',
                textTwo:'Over the course of the film Newman is pushed beyond his limits as Fletcher seeks to break him down and reinvent Newman in order to finally acheive perfection.',
                imageTwo:'Whiplash_Newman.jpeg',
            },
        ]
    }
]

export default data