const data =[
    {
        title: 'TV',
        image: 'Tv_shows.webp',
        tv: [
            {
                title:'Arcane',
                image: 'Arcane_Splash.jpg',
                thumbnail:'Arcane_Thumb.webp',
                youTube:'4Ps6nV4wiCE',
                textOne:'Arcane is set in the universe of league of legends and set before the events of the game. The show has some of the best cinematography and action sequences Ive seen in an animated series in a long time. The animation is especially great. At its core Arcane is a story about a dysfunctional family',
                imageOne:'Arcane_action_sequence.webp',
                textTwo:'The shows main shortcommings are so extremely nit picky but they do still exists. The show does have a few cliches and tropes which dont detract from the overall quality of the show and arent even noticeable on first viewing. The show does suffer from reduced tentions in scenes for those who might play League of legends as you know that characters from the game cant die, so unless you want to ruin most of the tension in scenes dont go looking at League of legends characters.',
                imageTwo:'Arcane_Image_2.jpeg',
            },
            {
                title:'Breaking Bad',
                image: 'Breaking_Bad_splash.jpg',
                thumbnail:'Breaking_bad_Thumb.jpg',
                youTube:'HhesaQXLuRY',
                textOne:'Breaking bad tells the story of Walter White, a 50 year old downtrodden chemistry teacher. After being diagnosed with Lung cancer and meeting ex student Jesse Pinkman, Walter dives into the meth cooking business.',
                imageOne:'Breaking_Bad_I_am_the_danger.jpg',
                textTwo:'The show is filled with many memorable characters and excellent writing. My main critisiscm is that dialogue can feel extremely static as it usually devolves into pointing at one character to the other depending on whos speaking.',
                imageTwo:'Breaking_Bad_back_and_forth.jpg',
            },
            {
                title:'Love Death and Robots',
                image: 'Love_death+robots_splash.png',
                thumbnail:'LD+R_Thumb.jpg',
                youTube:'wUFwunMKa4E',
                textOne:'Love Death+Robots is an eponymous anthology that gives its writters the tools of autonomy to make their vision a reality completely unfiltered. This has created one of the most unique shows that I know of as every episode is completely different from any other. With episodes ranging from yoghurt becoming sentient, monster blood sports and even a mirriade of alternate ways Hitler dies.',
                imageOne:'Love_death_and_robots_Uniqueness.webp',
                textTwo:'My only critisiscm is that I want more and that Season 2 didnt have quite as much flavour and diversity as season 1 yet is still solid on its own.',
                imageTwo:'Love_death+robots_Image_2.png',
            },
        ]
    }
]

export default data