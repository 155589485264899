<template>
  <div id="app">

    <!-- Nav Menu -->
    <NavBar />
    <!-- Key Full Path enables to use the same page template with diffrent data and will refresh -->
    <router-view :key="$route.fullPath"/> 

    <!-- Footer Menu -->
    
  </div>
</template>
<script>
import NavBar from '@/components/nav.vue'
export default {
  components:{
    NavBar
}
}
</script>
<style>
img{
  width: 100%;
}
#app{
  background-color: #000;
}
p{
  color: #fff;
}
</style>


